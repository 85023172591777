.table_grid .inte-flex >.inte-flex__item {
    margin-bottom: 12px;
}
.product-grid.data.skeleton thead.ant-table-thead th:nth-child(2), 
.product-grid.data.skeleton tbody.ant-table-tbody tr td:nth-child(2) {
    padding: 12px 8px;
}
.product-grid.data.skeleton tbody.ant-table-tbody tr td:nth-child(3) .inte__Skeleton--Text {
    margin: 0px auto;
}
.custom-item_align .inte__Skeleton--Text {
    margin: 0px auto;
}
.skeleton.profile_skeleton tbody.ant-table-tbody tr td, 
.skeleton.profile_skeleton thead.ant-table-thead tr th {
    padding: 12px 8px;
}
.ced-list__skeleton .inte__List {
    list-style-type: none !important;
}
.product-grid.skeleton td .status .inte__skeletonWrapper .inte__Skeleton--Text,
.product-grid.skeleton td .actoin .inte__skeletonWrapper .inte__Skeleton--Text,
.product-grid.skeleton td .total .inte__skeletonWrapper .inte__Skeleton--Text,
.product-grid.skeleton td .customer .inte__skeletonWrapper .inte__Skeleton--Text,
.product-grid.skeleton td .date .inte__skeletonWrapper .inte__Skeleton--Text,
.product-grid.skeleton td .managed_by .inte__skeletonWrapper .inte__Skeleton--Text,
.skeleton--center .inte__skeletonWrapper .inte__Skeleton--Text {
    display: block;
    margin: 0px auto;
}
.ced-tab__skeleton {
    overflow: auto;
}
@media only screen and (max-width: 1050px) {
    .mobile_responsive .ant-table-content td>div.inte__skeletonWrapper {
        float: none;
        width: 50%;
        float: right;
        text-align: right;
    }
    .mobile_responsive .ant-table-content td>div.inte__skeletonWrapper .inte__Skeleton--Text {
        display: inline-block;
    }
    .mobile_responsive.skeleton td.ant-table-cell::after {
        content: '';
        clear: both;
        display: table;
    }
    .mobile_responsive.skeleton .ant-table-content td::before {
        content: '';
        width: 100px;
        height: 22px;
        background: var(--inte-G30);
        float: left;
        border-radius: 0.4rem;
    }
}