/* product grid css */
.ced-filter__wrap {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
    border-color: var(--inte-G30);
}

.custom-popover-action button {
    padding-left: 0;
    padding-right: 0;
}

.custom-popover-action .inte-btn {
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-popover-action .inte-btn .inte__text {
    font-weight: var(--weight-normal);
}

.ced-pagination__wrap {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: var(--inte-G30);
}

.category-helptext {
    background-color: #aee9d1;
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
}

.inte__l-Layout--Content ul.inte__List {
    padding-left: 2rem;
}

.inte__l-Layout ul.inte__List {
    color: #4e4f52 !important;
}

.required-icon:after {
    content: "*";
    color: red;
    padding-left: 5px;
}

@media screen and (max-width: 767px) {
    .inte__r-Layout .inte-card.inte-card--default:first-child {
        padding: 0;
    }
}

@media screen and (max-width: 450px) {
    .flex-width .inte-flex__item--M33 {
        flex-basis: 40%;
        max-width: 40%;
    }

    .flex-width .inte-flex__item--M66 {
        flex-basis: 60%;
        max-width: 60%;
    }

    .inte-card.inte-card--bordered.flex-width {
        padding: 0;
        border: none;
    }
}