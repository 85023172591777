/* Common css */
.header-pb-16 .inte-card__Header {
  padding-bottom: 16px !important;
}
.inte-card__Header {
  align-items: start !important;
}
.ced-footer {
  color: var(--inte-G300) !important;
}
.footer-textlink a {
  font-size: 14px;
  font-weight: 500;
}
.footer-textlink a:hover {
  text-decoration: none;
  color: var(--inte-B80);
}
.p-12 {
  padding: 12px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.c-pointer {
  cursor: pointer !important;
}
.inte-pageHeader--fixed {
  top: 6.2rem !important;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
}
.custom-hr {
  border: none;
  height: 1px;
  background: var(--inte-G42);
}
.text-nowrap {
  white-space: nowrap;
}
.LH-0 {
  line-height: 0 !important;
}
.ced-logo {
  min-width: 60px;
}
.custom-link {
  color: var(--inte-B80) !important;
}
.inte-world_break {
  word-break: break-all;
}
.inte__text-btn:hover,
.inte__text-btn:focus {
  color: var(--inte-B80) !important;
  text-decoration: none !important;
}
.danger-btn .inte__text {
  color: var(--inte-R350) !important;
}
.inte__l-titleHelp {
  line-height: 20px !important;
}
.inte-PageHeader--hasMetadata {
  flex-wrap: wrap;
}
.ant-table-tbody > tr:last-child > td {
  border-bottom: 0px !important;
}
@media screen and (max-width: 600px) {
  .title-break .inte-formElement--Wrap h3 {
    max-width: 50px;
  }
}
.active-status .inte-flex__item:first-child,
.country-logo .inte__cardContent {
  line-height: 0;
}
.country-logo {
  padding: 14px 11px !important;
}
/*HEADER CSS START*/
.desk-menu .inte-flex__item .active {
  color: var(--inte-P900);
  position: relative;
}
.desk-menu .inte-flex__item a {
  color: var(--inte-G800);
  padding: 0 12px;
}
.desk-menu .inte-flex__item .active:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--inte-P900);
  bottom: -23px;
  left: 0;
}
.inte__Topbar {
  background-color: var(--inte-G0) !important;
  left: 0px !important;
}
@media screen and (max-width: 575px) {
  .inte-Pagination > .inte-flex {
    justify-content: center;
  }
}
.ced-dashboard .custom-spinner {
  margin-top: 6px;
}
.inte__Topbar ~ .inte__Main {
  padding-top: 6.2rem !important;
}
.bell-active {
  position: absolute;
  top: -5px;
  right: -5px;
}
.active-status.tick-icon .inte-flex__item:first-child {
  margin-top: 3px;
}
.inte-Grid-Table .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(0deg) !important;
}
.inte-Grid-Table .ant-table-row-expand-icon-expanded::before {
  transform: rotate(-180deg) !important;
}
@media screen and (min-width: 992px) {
  .mob-sidebar {
    display: none;
  }
  .inte__Sidebar--expanded .inte__Main {
    width: 100% !important;
  }
  .inte__Topbar {
    height: 6.2rem !important;
    padding-left: 3.2rem !important;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.1) !important;
  }
}
@media screen and (max-width: 991px) {
  .desk-menu {
    display: none;
  }
}
/*HEADER CSS END*/
/** Onboarding Common CSS**/
.max-w {
  max-width: 580px;
}
.onboarding .inte-card__Header {
  padding-bottom: 1.6rem !important;
}
.onboarding .inte-card__Header-Subtitle {
  margin-bottom: 0 !important;
}
.payment-method .inte-btn--onlyIcon,
.config-payment .inte-btn--onlyIcon {
  width: 4.4rem;
  height: 4.4rem;
}
.ant-table-thead > tr > th {
  font-weight: 600 !important;
  color: var(--inte-G800) !important;
}
/*DASHBOARD CSS START*/
.inte-card__Header .intel-flex--distribute-spaceBetween {
  gap: 0.8rem;
}
.text-italic {
  font-style: italic;
}
.inte--BadgeNarrow .inte--Badge-content span {
  white-space: nowrap;
}
/*DASHBOARD CSS END*/
/* COMMON GRID CSS START */
.ced-filter__wrap {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: 0 !important;
  border-color: var(--inte-G30) !important;
}
.ced-selected__row {
  padding: 12px 16px;
  background: #fafafa;
  border: 1px solid var(--inte-G30);
  border-bottom: 0;
  position: sticky;
  top: 6rem;
  z-index: 3;
  width: 100%;
  box-shadow: rgb(149 157 165 / 15%) 0px 8px 24px;
}
.custom-popover-action .inte-btn {
  border: 0 !important;
  padding: 7px 10px !important;
}
.custom-popover-action .inte-btn .inte__text {
  font-weight: var(--weight-normal) !important;
}
.ced-pagination__wrap {
  border-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-color: var(--inte-G30) !important;
}
.errorBtn .inte__text {
  color: var(--inte-R350) !important;
  text-decoration: underline !important;
  text-underline-offset: 4px;
}
.ErrorModal .inte-Modal__BodyWrapper {
  background: var(--inte-G20) !important;
}
/* COMMON GRID CSS END */
/* PRODUCT GRID CSS START */
.ced-product__grid .ant-table-expanded-row .ant-table {
  margin: -8px !important;
}
/* PRODUCT GRID CSS END */
/*CATEGORY TEMPLATE CSS START*/
.line-separator {
  display: flex;
  align-items: center;
}
.line-separator:before,
.line-separator:after {
  content: "";
  background-color: var(--inte-G42);
  height: 1px;
  width: 100%;
}
.line-separator:before {
  margin-right: 2.4rem;
}
.line-separator:after {
  margin-left: 2.4rem;
}
.ced-autocomplete span {
  color: var(--inte-G450) !important;
  font-weight: 400;
}
.category-helptext {
  background-color: #aee9d1;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
}
.inte__l-Layout ul.inte__List {
  color: var(--inte-G450) !important;
  line-height: 20px;
  padding-left: 2rem;
}
.required-icon:after {
  content: "*";
  color: red;
  padding-left: 5px;
}
/*CATEGORY TEMPLATE CSS END*/
/*ORDER GRID CSS START*/
@media screen and (max-width: 500px) {
  .order-details table colgroup col:nth-last-child(2),
  .order-details table colgroup col:last-child {
    width: 70px !important;
  }
  .charges {
    min-height: 44px;
  }
}
/*ORDER GRID CSS END*/
.mob-caraousel {
  padding: 0 4px;
}
.slick-second-carousel .slick-active.slick-current img {
  border: 1px solid var(--inte-G450);
  border-radius: 4px;
}
.slick-arrow ~ .slick-list > .slick-track {
  padding-bottom: 40px;
}
.mob-caraousel img {
  object-fit: contain;
}
.slick-second-carousel .slick-prev,
.slick-second-carousel .slick-next {
  font-size: 12px !important;
  color: currentColor;
  bottom: -14px !important;
  left: calc(50% + 6px);
  top: unset;
  width: 28px;
  border: 1px solid var(--inte-G50);
  height: 28px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 4px;
}
.slick-second-carousel .slick-prev {
  left: calc(50% - 32px);
}
.slick-second-carousel .slick-prev:before,
.slick-second-carousel .slick-next:before {
  content: unset !important;
}
.slick-second-carousel .slick-prev:hover,
.slick-second-carousel .slick-prev:focus,
.slick-second-carousel .slick-next:hover,
.slick-second-carousel .slick-next:focus {
  color: #000;
}
.ant-table-expanded-row-fixed {
  padding: 0 !important;
}
.custom-spinner {
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1.2rem;
}
@media screen and (max-width: 480px) {
  .inte__l-titleHelp {
    font-size: 1.4rem !important;
  }
}
.notification-tooltip {
  justify-content: end !important;
  left: unset !important;
  right: 0 !important;
  -webkit-transform: none !important;
  -webkit-transform: initial !important;
  transform: none !important;
  transform-origin: unset !important;
  animation: unset !important;
}
.notification-tooltip::after {
  left: unset !important;
  right: 10px !important;
}
@media screen and (max-width: 767px) {
  .notification-tooltip.right-unset {
    right: unset !important;
  }
  .notification-tooltip.right-unset::after {
    left: 20px !important;
    right: unset !important;
  }
}
.inte-ActionList--Wrapper.product-actions {
  min-width: 25rem !important;
  width: unset !important;
}
.product-status button {
  position: absolute;
  right: 0;
  top: 0;
}
@media screen and (max-width: 1150px) {
  .product-status button {
    display: none !important;
  }
}
.inte-Tabs--horizontal {
  position: relative;
}
.inte__Popover-Wrapper--Fake {
  pointer-events: none !important;
}
.ced-tab__skeleton {
  overflow: auto;
}
